import Vue from 'vue'

export default new Vue({
  created () {
    this.dataAudit = sessionStorage.audit != null ? JSON.parse(sessionStorage.audit) : null
  },
  data () {
    return {
      dataAudit: null
    }
  },
  methods: {
    audit (source, view) {
      this.dataAudit = {
        source,
        view
      }

      sessionStorage.setItem('audit', JSON.stringify(this.dataAudit))
    },
    stopAudit () {
      this.dataAudit = null
      sessionStorage.removeItem('audit')
    }
  }
})
